<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Delivered Date"
            label-for="customer_name"
          >
            <div class="position-relative">
              <flat-pickr
                v-model="filters['status_date']"
                class="form-control"
                :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                placeholder="Search Date"
              />
              <div
                v-if="filters['status_date']"
                class="flatpickr-cancel"
                @click="clearDate('status_date')"
              >
                <feather-icon icon="XIcon" />
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Rider Deposit Status"
            label-for="deposit_status"
          >
            <v-select
              v-model="filters['staff_deposit_status']"
              :options="financeStatusOptions"
              :reduce="option => option.key"
              label="name"
              placeholder="Select Status"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Agent Deposit Status"
            label-for="deposit_status"
          >
            <v-select
              v-model="filters['agency_deposit_status']"
              :options="financeStatusOptions"
              :reduce="option => option.key"
              label="name"
              placeholder="Select Status"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Branch Deposit Status"
            label-for="deposit_status"
          >
            <v-select
              v-model="filters['branch_deposit_status']"
              :options="financeStatusOptions"
              :reduce="option => option.key"
              label="name"
              placeholder="Select Status"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card
      no-body
    >
      <b-overlay
        :show="submitLoading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <div
          class="m-2"
        >
          <b-row
            cols="12"
            class="pt-1 pb-1"
          >
            <b-col
              class="justify-content-end"
            >
              <b-row
                class="d-flex align-items-center justify-content-end mr-1"
              >
                <b-col class="d-flex justify-content-start">
                  <div
                    class="font-weight-bolder text-muted mobile-margin"
                  >
                    {{ `${waybillSelected.length} record${waybillSelected.length === 1 ? '':'s'}` }} selected
                  </div>
                </b-col>
                <b-col md="3">
                  <v-select
                    v-model="selectedMerchant"
                    :options="merchantOptions"
                    :reduce="option => option.id"
                    label="name"
                    placeholder="Select Merchant"
                    class="ml-1 mobile-margin"
                    @input="getMerchantBusinessList()"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                </b-col>
                <!-- new filter called merchant business -->
                <b-col md="3">
                  <v-select
                    v-model="selectedMerchantBusiness"
                    :options="merchantBusinessOptions"
                    :reduce="option => option.id"
                    label="business_name"
                    class="ml-1 mobile-margin"
                    placeholder="Select Business"
                  >
                    <template v-slot:option="option">
                      {{ option.business_name }}
                    </template>
                  </v-select>
                </b-col>

                <div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="custom-button-color-blur mobile-margin mr-2"
                    @click="loadBills"
                  >
                    Get Waybills
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="custom-button-color mobile-margin"
                    :disabled="waybillSelected.length > 0? false : true"
                    @click="SubmitDeposit"
                  >
                    Submit
                  </b-button>
                </div>
                <div class="ml-1">
                  <ReportTableButtons
                    :items="items"
                    :json_fieldz="json_fields"
                    :name="`${downloadReportName} - Page ${page}.xls`"
                    :bulkname="`${downloadReportName}.xls`"
                    :fetch="fetchDataForMerchantBusinessNoPagination"
                    @refresh="fetchDataForMerchantBusiness"
                  />
                </div>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-row>
            <b-col md="12">
              <b-overlay
                id="overlay-background"
                blur="10px"
                opacity="0.30"
                rounded="sm"
                variant="light"
              >
                <b-table
                  :per-page="perPage"
                  :items="items"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  hover
                  responsive
                  striped
                  show-empty
                  class="table-responsive"
                >
                  <template #empty>
                    <TableDataFetching
                      :rows="items"
                      :data-loading="dataLoading"
                    />
                  </template>
                  <template #head(id)>
                    <div class="d-flex align-items-start">
                      <div>
                        <b-form-checkbox
                          id="selectAllCheckbox"
                          v-model="selectAllCheckbox"
                          name="selectAllCheckbox"
                          @change="onClickSelectAllCheckbox()"
                        />
                      </div>
                    </div>
                  </template>
                  <template #cell(id)="data">
                    <div class="d-flex align-items-start">
                      <div>
                        <div class="font-weight-bolder">
                          <b-form-checkbox
                            v-model="waybillSelected"
                            :value="data.item.waybill_number"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #cell(logo_url)="data">
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="font-weight-bolder">
                          {{ data.item.waybill_number }}
                        </div>
                        <div class="font-small-2">
                          {{ data.item.order_no }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #cell(customer_name)="data">
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="font-weight-bolder">
                          {{ data.value }}
                        </div>
                        <div class="font-small-2">
                          {{ data.item.customer_email }}
                        </div>
                        <div class="font-small-2">
                          <div><b>Primary number: </b>{{ data.item.customer_phone }}</div>
                        </div>
                        <div
                          v-if="data.item.customer_secondary_phone !== null"
                          class="font-small-2"
                        >
                          <div><b>Second number: </b>{{ data.item.customer_secondary_phone }}</div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #cell(cod)="data">
                    <span>{{ __numberWithCommas(data.value, true) }}</span>
                  </template>
                  <template #cell(collected_cod)="data">
                    <span>{{ __numberWithCommas(data.value, true) }}</span>
                  </template>
                  <template #cell(commission)="data">
                    <span>{{ __numberWithCommas(data.value, true) }}</span>
                  </template>
                  <template #cell(rider_deposit)="data">
                    <div
                      class="d-flex justify-content-center font-weight-bolder"
                      style="margin-bottom:2px;"
                    >
                      {{ data.value.ref_no || '---' }}
                    </div>
                    <div class="d-flex justify-content-center">
                      <finance-status-badge
                        :status-key="data.value.statuses? data.value.statuses.name : ''"
                        :finance-status="financeStatusOptions"
                      />
                    </div>
                  </template>
                  <template #cell(branch_deposit)="data">
                    <div
                      class="d-flex justify-content-center font-weight-bolder"
                      style="margin-bottom:2px;"
                    >
                      {{ data.value.ref_no || data.item.agency_deposit.ref_no || '---' }}
                    </div>
                    <div class="d-flex justify-content-center">
                      <finance-status-badge
                        :status-key="data.value.statuses? data.value.statuses.name : data.item.agency_deposit.statuses ? data.item.agency_deposit.statuses[0].name : ''"
                        :finance-status="financeStatusOptions"
                      />
                    </div>
                  </template>
                  <template #cell(status.key)="data">
                    <order-status-badge :status-key="data.value" />
                  </template>
                </b-table>
              </b-overlay>
              <div class="mx-2 mb-2">
                <b-row
                  cols="12"
                >
                  <b-col
                    class="d-flex align-items-center justify-content-sm-start"
                    md="3"
                  >
                    <label class="width-75">Per page</label>
                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="pageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block width-100"
                    />
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    class="d-flex align-items-center justify-content-sm-end"
                    md="9"
                  >
                    <span class="text-muted pagination-text  mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                    <b-pagination
                      v-model="page"
                      :total-rows="(meta.total)? meta.total : 0"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>

                  </b-col>

                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- <div>
          <b-form-group>
            <b-row>
              <b-col md="10" />
              <b-col
                md="2"
                class="d-flex align-items-center justify-content-end"
              >
                submit button was here
              </b-col>
              <b-row>
                <b-col cols="1" />
              </b-row>
            </b-row></b-form-group>
        </div> -->
      </b-overlay>
    </b-card>
    <deposit-detail-modal ref="deposit_detail_modal" />
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BRow,
  VBModal,
  BButton,
  VBTooltip,
  BPagination,
  BFormCheckbox,
  BTable,
  BOverlay,
} from 'bootstrap-vue'
import FinanceStatusBadge from '@core/components/status/FinanceStatusBadge.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import DepositDetailModal from '@/components/FinanceModuleForms/InfoModals/DepositDetailModal.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import OrderStatusBadge from '@core/components/status/OrderStatusBadge.vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const FinanceRepository = RepositoryFactory.get('finance')
const MerchantRepository = RepositoryFactory.get('merchant')

export default {
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BCol,
    BFormGroup,
    BFormCheckbox,
    BRow,
    flatPickr,
    vSelect,
    BCard,
    BButton,
    BPagination,
    BTable,
    BOverlay,
    BCardActions,
    OrderStatusBadge,
    FinanceStatusBadge,
    DepositDetailModal,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage, heightTransition],
  data() {
    return {
      filters: {},
      financeStatusOptions: [],
      selectedMerchant: '',
      selectedMerchantBusiness: '',
      merchantBusinessOptions: [],
      Rider: [],
      deselect: true,
      waybillSelected: [],
      merchantOptions: [],
      dataLoading: false,
      submitLoading: false,
      sort: '',
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      meta: {},
      dir: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'id',
          label: '#',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'logo_url',
          label: 'WayBill',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'customer_name',
          label: 'Customer Contact',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'cod',
          label: 'COD',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'collected_cod',
          label: 'Collected COD',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'commission',
          label: 'Commission',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'rider_deposit',
          label: 'Rider Deposit',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'branch_deposit',
          label: 'Warehouse Deposit',
          sortable: true,
          thClass: ' text-center',
          tdClass: ' text-center',
        },
        {
          key: 'status.key',
          label: 'Status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      items: [],
      json_fields: {
        'Waybill Number': 'waybill_number',
        'Order ID': 'order_no',
        'Customer Name': {
          field: 'customer_name',
          callback: value => `"${value}"`,
        },
        'Customer Email': {
          field: 'customer_email',
          callback: value => `"${value}"`,
        },
        'Customer Phone': 'customer_phone',
        'Second Phone Number': 'customer_secondary_phone',
        COD: 'cod',
        'Collected Cod': 'collected_cod',
        Commission: 'commission',
        'Rider Deposit Reference No': {
          field: 'rider_deposit',
          callback: value => (value.ref_no || 'N/A'),
        },
        'Warehouse Deposit Reference No': {
          field: 'branch_deposit',
          callback: value => (value.ref_no || 'N/A'),
        },
        Status: {
          field: 'status.name',
          callback: value => `"${value}"`,
        },
      },
      selectAllCheckbox: false,
      downloadReportName: 'Merchant-New-Invoices',
      pageWithSelectAll: [],
    }
  },
  watch: {
    deep: true,
    page() {
      this.fetchDataForMerchantBusiness()
    },
    perPage() {
      this.fetchDataForMerchantBusiness()
    },
  },
  mounted() {
    this.financeStatusOptions = JSON.parse(localStorage.getItem('finance_statuses'))
    this.getMerchantList()
  },
  methods: {
    async getMerchantList() {
      this.merchantOptions = localStorage.getItem('merchant_list') ? JSON.parse(localStorage.getItem('merchant_list')) : (await MerchantRepository.getMerchantListForDropdown()).data.data
      if (!localStorage.getItem('merchant_list')) localStorage.setItem('merchant_list', JSON.stringify(this.merchantOptions))
    },
    async loadBills() {
      if (!this.selectedMerchant) {
        this.showErrorMessage('Please Select a Merchant')
      } else if (!this.selectedMerchantBusiness) {
        this.showErrorMessage('Please Select a Merchant Business')
      } else {
        this.deselectAll()
        await this.fetchDataForMerchantBusiness()
      }
    },
    async fetchDataForMerchantBusiness() {
      this.dataLoading = true
      this.items = []
      const isChecked = this.pageWithSelectAll.includes(this.page)
      if (!isChecked) {
        this.selectAllCheckbox = false
      } else {
        this.selectAllCheckbox = true
      }
      if (this.selectedMerchantBusiness) {
        try {
          // eslint-disable-next-line no-underscore-dangle
          this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
          const { data } = (await FinanceRepository.getMerchantBusinessInvoiceOrders(this.page, this.perPage, this.selectedMerchantBusiness, this.filterQuery))
          this.items = data.data
          this.meta = data.meta
        } catch (e) {
          this.convertAndNotifyError(e)
        }
      }
      this.dataLoading = false
    },
    async fetchDataForMerchantBusinessNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await FinanceRepository.getMerchantBusinessInvoiceOrdersNoPagination(this.selectedMerchantBusiness, this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async getMerchantBusinessList() {
      try {
        this.businesses = []
        this.merchant_business_id = null
        this.merchant_id = this.selectedMerchant
        const { data } = (await MerchantRepository.getMerchantBusinessListDropdown(this.merchant_id)).data
        this.merchantBusinessOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    handleChangePage(page) {
      this.page = page
      this.fetchDataForMerchantBusiness()
    },
    applyFilters() {
      this.fetchDataForMerchantBusiness()
      this.deselectAll()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchDataForMerchantBusiness()
      })
    },
    selectAll() {
      this.waybillSelected = this.items.map(n => n.waybill_number)
      this.deselect = false
    },
    deselectAll() {
      this.waybillSelected = []
      this.selectAllCheckbox = false
      this.items = []
      this.meta = {}
    },
    onClickSelectAllCheckbox() {
      if (this.selectAllCheckbox) {
        // If the "Select All" checkbox is checked, select all waybill numbers.
        this.waybillSelected = [...this.waybillSelected, ...this.items.map(n => n.waybill_number)]
        this.pageWithSelectAll = [...this.pageWithSelectAll, this.page]
      } else {
        this.waybillSelected = []
        this.pageWithSelectAll = []
      }
    },
    SubmitDeposit() {
      const depositDate = new Date().toISOString().slice(0, 10)
      if (this.waybillSelected.length === 0) {
        this.showErrorMessage('Please Select Waybills First')
      } else if (this.selectedMerchant !== this.items[0].merchant.id) {
        this.showErrorMessage('Selected orders do not match the selected merchant')
      } else {
        const deposit = {
          merchant_id: this.selectedMerchant,
          merchant_business_id: this.selectedMerchantBusiness,
          waybill_numbers: this.waybillSelected,
          deposit_date: depositDate,
          expenses: [],
        }
        this.handleSubmit(deposit)
      }
    },
    async handleSubmit(deposit) {
      this.$refs.deposit_detail_modal.openModal(this.waybillSelected, this.createInvoice, deposit, 'merchant')
    },
    async createInvoice(deposit) {
      const res = await FinanceRepository.createMerchantInvoice(deposit)
      if (res.status === 201) {
        this.showSuccessMessage('Invoice Created Successfully')
        await this.$router.push('/operations/merchant-finance')
      } else {
        this.showErrorMessage('Cannot Add Invoice')
        await this.$router.push('/operations/merchant-finance')
      }
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style scoped lang="scss">
@import '../../@core/scss/vue/libs/vue-select.scss';
@import '../../@core/scss/vue/libs/vue-flatpicker.scss';
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
.custom-button-color-blur {
  background-color: #6da5ff !important;
  border-color: #6da5ff !important;
}
@media (max-width: 767px) {
  .mobile-buttons {
    margin-bottom: 2rem;
  }
  .table-responsive {
    position: sticky;
    top: 0;
  }
  .mobile-margin {
    margin-bottom: 20px;
    width: 100%;
  }
  .pagination-text {
    font-size: 0.75rem;
  }
}
</style>
