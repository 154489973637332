<template>
  <div>
    <b-badge :variant="'light-' + statusColor">
      {{ statusName }}
    </b-badge>
  </div>
</template>

<script>
import {
  BBadge,
} from 'bootstrap-vue'

export default {
  name: 'OrderStatusBadge',
  components: {
    BBadge,
  },
  props: {
    statusKey: {
      type: String,
      default: 'N/A',
    },
    financeStatus: {
      type: Array,
      default: null,
    },
  },
  computed: {
    statusColor() {
      const obj = this.financeStatus.find(n => n.key === this.statusKey)
      return obj ? obj.color : 'primary'
    },
    statusName() {
      const obj = this.financeStatus.find(n => n.key === this.statusKey)
      return obj ? obj.name : 'N/A'
    },
  },
}
</script>
